
/*::-webkit-scrollbar {*/
/*    width: 0px;*/
/*    background: transparent; !* make scrollbar transparent *!*/
/*}*/

body {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
    display: none !important;  /* Safari and Chrome */
    width: 0 !important;
}

textarea:focus, input:focus{
    outline: none;
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.no-spin {
    -moz-appearance:textfield !important;
}

body {
    color: #1e1e1e;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select:none;
    user-select:none;
    -o-user-select:none;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
    /*overflow-y: hidden !important;*/
}

@font-face {
  font-family: 'poppins black';
  src: url("../../assets/fonts/Poppins-Black.ttf");
  font-style: normal;
  font-weight: bold;
}

.poppins-black {
  font-family: poppins black !important;
}

@font-face {
  font-family: 'poppins bold';
  src: url("../../assets/fonts/Poppins-Bold.ttf");
  font-style: normal;
  font-weight: bold;
}

.poppins-bold {
  font-family: poppins bold !important;
}

@font-face {
  font-family: 'poppins semibold';
  src: url("../../assets/fonts/Poppins-SemiBold.ttf");
  font-style: normal;
  font-weight: bold;
}

.poppins-semibold {
  font-family: poppins semibold !important;
}


@font-face {
  font-family: 'poppins medium';
  src: url("../../assets/fonts/Poppins-Medium.ttf");
  font-style: normal;
  font-weight: bold;
}

.poppins-medium {
  font-family: poppins medium !important;
}

@font-face {
  font-family: 'poppins regular';
  src: url("../../assets/fonts/Poppins-Regular.ttf");
  font-style: normal;
  font-weight: bold;
}

.poppins-regular {
  font-family: poppins regular !important;
}

@font-face {
  font-family: 'notosans bold';
  src: url("../../assets/fonts/NotoSans-Bold.ttf");
  font-style: normal;
  font-weight: bold;
}

.notosans-bold {
  font-family: notosans bold !important;
}

@font-face {
  font-family: 'notosans light';
  src: url("../../assets/fonts/NotoSansKR-Light.ttf");
  font-style: normal;
  font-weight: bold;
}

.notosans-light {
  font-family: notosans light !important;
}

@font-face {
  font-family: 'notosans regualer';
  src: url("../../assets/fonts/NotoSansKR-Regular.ttf");
  font-style: normal;
  font-weight: bold;
}

.notosans-regular {
  font-family: notosans regualer !important;
}

@font-face {
  font-family: 'notosans black';
  src: url("../../assets/fonts/NotoSansKR-Black.ttf");
  font-style: normal;
  font-weight: bold;
}

.width-100 {
    width: calc(100%);
}

.width-100-vw {
    width: 100dvw !important;
}

.height-80 {
    height: calc(80%);
}

.height-100 {
    height: calc(100%);
}

.height-100vh {
    height: calc(100dvh - 80px) !important;
}

.mobile-height-100vh {
    height: calc(100vh - 0px);
}

.container-img {
    width: 100vw;
    max-width: 100vw;
    height: calc(100dvh - 70px);
    object-fit: cover !important;
}

.mobile-container-img {
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 0px);
    object-fit: cover !important;
}

.section {
    scroll-snap-align: center;
    /*height: calc(100vh - 80px);*/
}


.notosans-black {
    font-family: notosans black !important;
}

body {
    font-family: notosans regualer;
}

.button {
    height: 48px;
    border-radius: 16px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.border-none {
    border: none !important;
}

.black-bg {
    background-color: #000 !important;
}

.light-black-bg {
    background-color: #252525 !important;
}

.gray-bg {
    background: #D9D9D9;
}

.gray {
    color: #4c4c4c;
}

.gray9 {
    color: #999999;
}

.darkgray {
    color: #313131;
}

.darkgray-bg {
    background-color: #313131;
}

.light-gray-bg {
    background: #f8f8f8;
}

.dark-gray {
    color: #a2a5aa;
}

.darker-gray {
    color: #6e6f73;
}

.purple {
    color: #6326FE !important;
}

.purple-bg {
    background-color: #6326FE !important;
}

.yellow {
    color: #D3F214;
}

.yellow-bg {
    background-color: #D3F214;
}

.yellow-border {
    border: solid 1px #D3F214;
}

.blur-yellow {
    color: rgba(211, 242, 20, 0.7);
}

.blur-yellow-border {
    border: solid 1px rgba(211, 242, 20, 0.7);
}

.blur-purple-bg {
    background-image: linear-gradient(to top, #5000FF, rgba(0, 0, 0, 0));
}

.red {
    color: #fd4242 !important;
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.lines-1 {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1;
   -webkit-box-orient: vertical;
}

.lines-2 {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}

.lines-3 {
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-10 {
    opacity: 1 !important;
}

.flex-1 {
    display: flex;
    flex: 1;
}

.flex-1-2 {
    display: flex;
    flex: 1.2;
}

.flex-2 {
    display: flex;
    flex: 2 !important;
}

.flex-3 {
    display: flex;
    flex: 3 !important;
}

.flex-4 {
    display: flex;
    flex: 4 !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-wo-center {
    display: flex;
    flex-direction: row;
}


.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-center {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.vertical-center {
    align-items: center;
}

.vertical-end {
    align-items: flex-end;
}

.horizontal-center {
    justify-content: center;
}

.horizontal-end {
    justify-content: flex-end;
}

.font-8 {
    font-size: 8px;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-25 {
    font-size: 25px;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}

.font-36 {
    font-size: 36px;
}

.font-40 {
    font-size: 40px;
}

.font-45 {
    font-size: 45px;
}

.padding-10 {
    padding: 10px;
}

.padding-16 {
    padding: 16px;
}

.padding-20 {
    padding: 20px;
}

.padding-left {
    padding-left: 20px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-horizontal-10 {
    padding: 0 10px;
}

.padding-horizontal-14 {
    padding: 0 14px;
}

.padding-horizontal-15 {
    padding: 0 15px;
}

.padding-horizontal-17 {
    padding: 0 17px;
}

.padding-horizontal-20 {
    padding: 0 20px;
}

.padding-horizontal-22 {
    padding: 0 22px;
}

.padding-horizontal-24 {
    padding: 0 24px;
}

.padding-horizontal-29 {
    padding: 0 29px;
}

.padding-horizontal-33 {
    padding: 0 33px;
}

.padding-horizontal-34 {
    padding: 0 34px;
}

.padding-horizontal-35 {
    padding: 0 35px;
}

.padding-horizontal-38 {
    padding: 0 38px;
}

.padding-vertical-20 {
    padding: 20px 0;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.margin-top-2 {
    margin-top: 2px;
}

.margin-top-3 {
    margin-top: 3px;
}

.margin-top-4 {
    margin-top: 4px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-6 {
    margin-top: 6px;
}

.margin-top-7 {
    margin-top: 7px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-top-14 {
    margin-top: 14px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-top-18 {
    margin-top: 18px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-2vh {
    margin-top: 2vh;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-top-26 {
    margin-top: 26px;
}

.margin-top-28 {
    margin-top: 28px;
}

.margin-top-32 {
    margin-top: 32px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-38 {
    margin-top: 38px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-48 {
    margin-top: 48px;
}

.margin-top-54 {
    margin-top: 54px;
}

.margin-top-62 {
    margin-top: 62px;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-14 {
    margin-bottom: 14px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-right-4 {
    margin-right: 4px;
}

.margin-right-6 {
    margin-right: 6px;
}

.margin-left-2 {
    margin-left: 2px;
}

.margin-left-3 {
    margin-left: 3px;
}

.margin-left-4 {
    margin-left: 4px;
}

.margin-left-6 {
    margin-left: 6px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-13 {
    margin-left: 13px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-left-18 {
    margin-left: 18px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-22 {
    margin-left: 22px;
}

.margin-left-28 {
    margin-left: 28px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-14 {
    margin-right: 14px;
}

.margin-right-16 {
    margin-right: 16px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-20 {
    margin: 20px;
}

.margin-28 {
    margin: 28px;
}

.artist-wrapper {
    padding: 16px 20px 0px 16px;
    margin-bottom: 4px;
}

.interest-artist-wrapper {
    padding: 16px 20px 16px 16px;
    border-bottom: solid 1px #eceff2;
}

.interest-artist-wrapper .artist-name {
    margin-left: 0;
    margin-top: 0;
    font-weight: 700;
    font-size: 20px;
}

.interest-artist-wrapper .artist-en-name {
    margin: 12px 0 0 8px;
}

.user-profile-img {
    object-fit: cover;
    width: 42px;
    height: 42px;
    border-radius: 42px;
}

.artist-name {
    font-size: 24px;
    margin-left: 8px;
    margin-top: 4px;
}

.profile-right-img {
    width: 24px;
    height: 24px;
    margin-top: 6px;
}

.artist-en-name {
    font-size: 12px;
    color: #a2a5aa;
    margin-top: 2px;
}

.flex-grow {
    flex-wrap: wrap !important;
    /*alignItems: 'flex-start',*/
}

.tag-wrapper {
    width: auto;
    height: 25px;
    border-radius: 4px;
    margin-right: 4px;
    background-color: #eceff2;
    padding: 0 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tag {
    font-size: 12px;
    color: #424345;
    font-weight: 500;
}

.gray-title {
    font-size: 12px;
    font-weight: 500;
    color: #a2a5aa;
    margin-bottom: 4px;
}

.count {
    font-size: 20px;
    color: #1e1e1e;
    line-height: 28px;
}

.gray-count {
    font-size: 12px;
    color: #a2a5aa;
    margin-left: 4px;
}

.count-wrapper {
    margin-right: 14px;
    margin-left: 14px;
}

.first-wrapper {
    margin-left: 0;
}

.count-border {
    border-right: solid 1px #eceff2;
    height: 20px;
    margin-top: 10px;
}

.right-border {
    border-right: solid 1px #cccfd3;
    height: 28px;
    margin-left: 20px;
    margin-top: 5px;
}

.count-box-border {
    border-right: solid 1px #424345;
    height: 32px;
    margin-top: 30px;
}

.count-right {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-top: 4px;
}

.count-graph-img {
    width: 28px;
    height: 28px;
}

.banner-img {
    width: calc(100% - 0px);
}

.banner-wrapper {
    border-radius: 10px !important;
    padding: 0;
}

.banner-component-img {
    width: 560px !important;
    height: 600px !important;
    border-radius: 10px !important;
    object-fit: cover;
}

.draggable-none {
    -webkit-user-drag: none;
}

.gray-divider {
    width: 100%;
    height: 16px;
    background-color: #f8f8f8;
}

.filter-wrapper {
    width: auto;
    height: 28px;
    border-radius: 32px;
    background-color: #eceff2;
    padding: 0 8px 0 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-text {
    font-size: 14px;
    font-weight: 500;
    color: #343436;
    white-space: nowrap;
}

.filters-wrapper {
    overflow: scroll;
    padding-left: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.filters-wrapper::-webkit-scrollbar {
    display: none;
}

.active-filter-wrapper {
    background-color: #D3F214;
    color: #333 !important;
}

.active-filter-text {
    color: #fff;
}

.item-img {
    object-fit: cover;
    width: 180px;
    height: 240px;
}

.item-img-wrapper {
    object-fit: cover;
    margin-right: 8px;
}

.profile-img {
    object-fit: cover;
    width: 24px;
    height: 24px;
    border-radius: 24px;
}

.profile-name {
    font-size: 16px;
    font-weight: 500;
    color: #1e1e1e;
    margin-left: 8px;
    line-height: 22.4px;
}

.profile-en-name {
    color: #a2a5aa;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    margin-left: 0;
}

.item-ranking {
    position: absolute;
    left: 0;
    bottom: 4px;
    color: #ffffff;
    width: 40px;
    height: 36px;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.item-img-wrapper {
    position: relative;
}

.item-heart {
    position: absolute;
    right: 0;
    bottom: 14px;
    width: 40px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    elevation: above;
}

.heart-count {
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
}

.item-heart-img {
    width: 32px;
    height: 32px;
}

.bottom-space {
    height: 60px;
}

.right-icon {
    width: 6px;
    height: 10px;
}

.down-icon {
    width: 10px;
    height: 6px;
}

.icon-10 {
    width: 10px;
    height: 10px;
}

.icon-12 {
    width: 12px;
    height: 12px;
}

.icon-14 {
    width: 14px;
    height: 14px;
}

.icon-15 {
    width: 15px;
    height: 15px;
}

.icon-16 {
    width: 16px;
    height: 16px;
}

.icon-17 {
    width: 17px;
    height: 17px;
}

.icon-18 {
    width: 18px;
    height: 18px;
}

.icon-19 {
    width: 19px;
    height: 19px;
}

.icon-20 {
    width: 20px;
    height: 20px;
}

.icon-21 {
    width: 21px;
    height: 21px;
}

.icon-22 {
    width: 22px;
    height: 22px;
}

.icon-23 {
    width: 23px;
    height: 23px;
}

.icon-24 {
    width: 24px;
    height: 24px;
}

.icon-25 {
    width: 25px;
    height: 25px;
}

.icon-28 {
    width: 28px;
    height: 28px;
}

.icon-32 {
    width: 32px;
    height: 32px;
}

.icon-36 {
    width: 36px;
    height: 36px;
}

.icon-42 {
    width: 42px;
    height: 42px;
}

.icon-43 {
    width: 43px;
    height: 43px;
}

.icon-48 {
    width: 48px;
    height: 48px;
}

.icon-50 {
    width: 50px;
    height: 50px;
}

.icon-54 {
    width: 54px;
    height: 54px;
}

.white-bg {
    background-color: #fff !important;
}

.white-border {
    border: solid 1px #fff !important;
}

.blur-white-border {
    border: solid 1px #ffffff75 !important;
}

.white {
    color: #fff;
}

.blur-white {
    color: rgba(255, 255, 255, 0.75)
}

.blur-white-bg {
    background-color: rgba(255, 255, 255, 0.75)
}

.more-blur-white {
    color: rgba(255, 255, 255, 0.55)
}

.pink {
    color: #ff007a;
}

.pink-bg {
    background-color: #ff007a;
}

.border-bottom {
    padding-bottom: 10px;
    border-bottom: solid 2px #1e1e1e;
}

.white-border-bottom {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.white-border-top {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
}

.whiter-border-bottom {
    border-bottom: solid 3px rgba(255, 255, 255, 1);
}

.lighter-gray-border-bottom {
    padding-bottom: 10px;
    border-bottom: solid 1px #f8f8f8;
}

.border-top {
    border-top: solid 2px #eceff2;
}

.gray-border-bottom {
    padding-bottom: 16px;
    border-bottom: solid 2px #cccfd3;
}

.white-border-bottom {
    border-bottom: 1px solid #FFFFFF1A
}

.btn-title {
    font-size: 16px;
    font-weight: 700;
    color: #1e1e1e;
    margin-top: 2px;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 600;
}

.medium {
    font-weight: 500;
}

.light {
    font-weight: 300;
}


.product-info {
    padding: 20px;
}

.artist-profile-img {
    object-fit: cover;
    width: 53px;
    height: 65px;
    margin-right: 12px;
}

.delivery-info {
    padding: 16px 20px;
    background-color: #eceff2;
}

.delivery-info-title {
    font-size: 16px;
    font-weight: 700;
    color: #1e1e1e;
    border-bottom: solid 4px #1e1e1e;
    padding-bottom: 8px;
}

.delivery-info-text {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
    line-height: 19.6px;
}

.delivery-info-gray-text {
    font-size: 14px;
    font-weight: 500;
    color: #a2a5aa;
    margin-top: 8px;
}

.artist-info-title {
    font-size: 16px;
    font-weight: 700;
    color: #1e1e1e;
    border-bottom: solid 4px #1e1e1e;
    padding-bottom: 8px;
}

.artist-info {
    padding: 16px 20px;
}

.product-artist {
    background-color: #1e1e1e;
    padding: 16px;
}

.product-artist .artist-name {
    font-size: 16px;
    color: #fff;
    margin-left: 0;
    margin-top: 8px;
    line-height: 22.4px;
}

.product-artist .artist-en-name {
    font-size: 12px;
    font-weight: 500;
    color: #a2a5aa;
    margin-top: 2px;
    line-height: 16.8px;
    padding-bottom: 7px;
    border-bottom: solid 1px #fff;
    max-width: 173px;
}

.product-artist .gray-text {
    font-size: 14px;
    font-weight: 500;
    color: #a2a5aa;
}

.product-artist .info-data {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-top: 4px;
}

.artist-image {
    object-fit: cover;
    width: 114px;
    height: 150px;
}

.white-space {
    white-space: pre-wrap;
    line-height: 20px;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.cursor {
    cursor: pointer;
}

.full-img {
    width: 100%;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-start {
    display: flex;
    align-items: start;
    justify-content: start;
}

.underline {
    text-decoration: underline;
}

.blur-black {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 0px);
    height: 40px;
    background-image: linear-gradient(to bottom, #181818, rgba(0, 0, 0, 0));
}

.blur-black-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 0px);
    height: 40px;
    background-image: linear-gradient(to top, #181818, rgba(0, 0, 0, 0));
}

.nowrap {
    white-space: nowrap;
}

.pre-wrap {
    white-space: pre-wrap;
}

.carousel-index {
    background-color: #525252;
    padding: 2px 6px;
    border-radius: 12px;
}

textarea {
    resize: none;
}


[data-rsbs-overlay] {
    background: #525252 !important;
}

[onClick] {
    cursor: pointer !important;
}

body {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
    overflow: -moz-scrollbars-none !important;
}

body::-webkit-scrollbar { width: 0 !important }

body::-webkit-scrollbar {
    display: none !important;
}

.no-scroll {
    -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
    overflow: -moz-scrollbars-none !important;
}

.no-scroll::-webkit-scrollbar { width: 0 !important }

.no-scroll::-webkit-scrollbar {
    display: none !important;
}

.scroll-snap {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory !important;
    height: calc(100vh - 80px);
    width: 100%;
}

.apply-section {
    scroll-snap-align: start;
}

.bottom-sheet {
    max-width: 1120px !important;
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes left-to-center{
  0% {
    transform:  translate(250px,0px)  ;
  }
  100% {
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes left-to-center{
  0% {
    -moz-transform:  translate(250px,0px)  ;
  }
  100% {
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes left-to-center {
  0% {
    -webkit-transform:  translate(250px,0px)  ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes left-to-center {
  0% {
    -o-transform:  translate(250px,0px)  ;
  }
  100% {
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes left-to-center {
  0% {
    -ms-transform:  translate(250px,0px)  ;
  }
  100% {
    -ms-transform:  translate(0px,0px)  ;
  }
}

@media print {
    html, body {
       display: none;  /* hide whole page */
    }
}

