.header {
    width: calc(100% - 4.5vw);
    height: 80px;
    background-color: #000;
    display: flex;
    flex-direction: row;
    padding: 0 1.5vw 0 3vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.brochure {
    background-color: #868686;
    color: #fff;
    padding: 10px 32px;
    border-radius: 8px;
}

.select-box {
    background-color: #474747;
    color: #fff;
    padding: 10px 16px;
    border-radius: 8px;
}

.select {
    background-color: #474747;
    border: none;
    color: #fff;
}

.select-menu {
    background-color: #474747;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 10px !important;
    border: none !important;
}

.margin-left {
    margin-left: 4vw;
}
