.blur-bg {
    height: 28vw;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
}

.vertical-video {
    width: 100%;
    height: calc(100dvh - 80px) !important;
    object-fit: cover;
}

.mobile-video-wrapper {
    margin: 5px;
    border-radius: 20px;
    overflow: hidden;
    text-align: left;
}

.mobile-vertical-video {
    width: 100%;
    height: calc(200dvw - 210px);
    object-fit: cover !important;
    border-radius: 20px;
}

.border {
    width: 70vw;
    border-top: solid 1px #000;
    margin: 10px 0 30px 0;
}

.screen-btn {
    background-color: #2b2b2b;
    color: #fcfff8;
    font-size: 20px;
    height: 3vw;
    width: auto;
    border-radius: 30px;
    z-index: 99999;
    padding: 6px 30px;
}

.mobile-screen-btn {
    background-color: #2b2b2b;
    color: #fcfff8;
    font-size: 20px;
    width: auto;
    border-radius: 30px;
    z-index: 99999;
    padding: 0 10px;
}

.screen {
    cursor: url(../../assets/images/play_128.png), pointer !important;
}

.z-index {
    z-index: 99 !important;
}

.width-160px {
    width: 120px !important;
    height: 30px !important;
    font-size: 1.1rem !important;
}

.adproduct-menu {
    border-radius: 33px;
    width: auto;
    height: auto;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    border: solid 1px #000;
    margin-left: 9px;
    font-size: 20px;
    margin-bottom: 9px;
}

.tag-menu {
    border-radius: 33px;
    width: 248px;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    margin-left: 9px;
    font-size: 20px;
    border: solid 1px #959595;
}

.mobile-adproduct-menu {
    border-radius: 33px;
    width: 134px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    border: solid 1px #000;
    margin-left: 9px;
    font-size: 15px;
}

.active-menu {
    color: #fff;
    background-color: #4f4f4f !important;
}

/*.black {*/
/*    color: #333 !important;*/
/*}*/

.text-input-wrapper {
    width: 100%;
}

.text-input {
    width: 100%;
    background-color: #252525;
    border: none;
    border-bottom: solid 1px #565656;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    /*height: 50px;*/
}

.text-input::placeholder {
    color: #fff;
}

.dark-placeholder::placeholder {
    color: #424242
}

.contact-file-box {
    /*background-color: #4e4e4e;*/
    /*border-radius: 19px;*/
    /*min-width: 69px;*/
    /*height: 38px;*/
}

.file-box {
    background-color: #4e4e4e;
    border-radius: 19px;
    min-width: 69px;
    height: 38px;
}

.file-input-wrapper {
    width: calc(100% - 20px);
    background-color: #252525;
    padding: 34px 30px;
    border: dotted 2px #fff;
}

.mobile-file-input-wrapper {
    /*background-color: #272727;*/
    padding: 32px 30px;
    border: dotted 2px #fff;
}

.button {
    color: #fff;
    background-color: #4e4e4e;
    font-size: 24px;
    border-radius: 5px;
}

.contact-wrapper {
    width: calc(100%);
    margin: 0 6vw 0 2vw;
    margin-top: 0 !important;
    /*padding: 3vw 4.5vw 3vw 3vw;*/
}

.career-box {
    max-width: 45%;
}

.mobile-career-box {
    padding: 20px;
}

.mobile-contact-wrapper {
    width: calc(100%);
    margin-top: 0;
    padding: 20px;
}

.dot {
    width: 10px !important;;
    height: 10px !important;
    border-radius: 10px;
    background-color: #aaa;
    display: inline-block;
    box-shadow: none !important;
    opacity: 1 !important;
    margin: 4px !important;
    border: solid 1px #aaa;
}

.agreement {
    height: 103px;
    overflow-y: scroll;
    margin-top: 27px;
    border: solid 1px #D5D5D5;
    padding: 11px 7px;
    font-size: 12px;
}

.contact-agreement {
    height: 103px;
    overflow-y: scroll;
    margin-top: 27px;
    padding: 24px;
    background-color: #272727;
    font-size: 12px;
}

.mobile-agreement {
    height: 103px;
    overflow-y: scroll;
    margin-top: 27px;
    padding: 24px;
    background-color: #000;
    font-size: 12px;
}

.partners-wrapper {
    border-radius: 20px;
    overflow: hidden;
    margin: 8vw;
}

.partner-btn {
    flex: 1;
    height: 46px;
    color: #fff;
    background-color: #464646;
    font-size: 14px;
}

.active-partner-btn {
    flex: 1;
    height: 46px;
    color: #000;
    background-color: #fff;
    font-size: 14px;
}

.mobile-target-wrapper {
    height: calc(70vh);
}

.mobile-da-wrapper {
    height: calc(80vh);
}

.mobile-wrapper {
    /*height: calc(100vh - 20px);*/
}

.publisher-image {
    width: 30vw !important;
    object-fit: contain;
}

.pc-container {
    margin-top: 80px;
}

.key-video {
    max-width: 100%;
    width: 100vw;
    height: calc(100dvh - 80px);
    object-fit: cover;
}

.home-title {
    font-size: 6.5vw;
}

.main-title {
    font-size: 5vw;
}

.en-title {
    font-size: 3.8vw;
}

.title {
    font-size: 3.4vw;
}

.sub-title {
    font-size: 3vw;
}

.medium-title {
    font-size: 2.5vw;
}

.small-title {
    font-size: 2vw;
}

.main-video {
    height: 50vmin;
}

.transparent-bg {
    background-color: transparent !important;
}

.no-border {
    border: none !important;
    outline: none !important;
    border-style: none !important;
}

.no-border:focus {
    border: none !important;
    outline: none !important;
}


/* PC */
@media only screen and (min-width: 1600px){
    .pc-container {
        display: inherit !important;
    }
    .pad-container {
        display: none !important;
    }
    .mobile-container {
        display: none !important;
    }
    .partnership-image {
        height: 50vmin;
    }
    .ad-product-contents {
        margin-top: -3vw;
    }
    .main-title {
        font-size: 5vw;
    }
    .title {
        font-size: 3.4vw;
    }
    .sub-title {
        font-size: 3vw;
    }
    .main-video {
        height: 50vh;
    }
    .exposure-media {
        height: 30vmin;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px)  {
    .pc-container {
        display: inherit !important;
    }
    .pad-container {
        display: none !important;
    }
    .mobile-container {
        display: none !important;
    }
    .partnership-image {
        height: 40vmin;
    }
    .ad-product-contents {
        margin-top: -5vw;
    }
    .main-video {
        height: 50vh;
    }
    .exposure-media {
        height: 25vmin;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
    .pc-container {
        display: inherit !important;
    }
    .pad-container {
        display: none !important;
    }
    .mobile-container {
        display: none !important;
    }
    .partnership-image {
        height: 50vmin;
    }
    .ad-product-contents {
        margin-top: -7vw;
    }
    .exposure-media {
        height: 25vmin;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1120px) {
    .target-video {
        margin-top: -20dvh;
    }
}

/* Pad */
@media only screen and (min-width: 450px) and (max-width: 960px) {
    .pc-container {
        display: inherit !important;
    }
    .pad-container {
        display: inherit !important;
    }
    .mobile-container {
        display: none !important;
    }
    .header-menu {
        font-size: 14px;
        margin-left: 0;
    }
    .footer {
        margin-top: 0px;
        padding-top: 0
    }
    .footer .font-40 {
        font-size: 30px !important;
        margin-bottom: 20px;
    }
    .adproduct-menu {
        font-size: 16px !important;
    }
    .width-160px {
        width: 110px !important;
    }
    .adproduct-text-wrapper {
        margin-left: 3vw;
    }
    .target-btn-wrapper {
        margin-left: 37vw !important;
        margin-right: 30px;
    }
    .target-text-wrapper {
        margin-left: -2.5vw !important;
    }
    .interplay-btn-wrapper {
        margin-right: 30px;
    }
    .interplay-desc-wrapper {
        padding-right: 30px !important;
        font-size: 16px !important;
    }
    .device-text {
        min-height: 60px !important;
    }
    .career-step-img-wrapper {
        width: 80vw !important;
    }
    .file-text {
        font-size: 20px !important;
    }
    .footer {
        min-height: 27vw !important;
    }
    .footer > .flex-row {
        padding-top: 5vw;
    }
    .brochure {
        font-size: 12px !important;
    }
    .partnership-image {
        height: 50vmin;
    }
    .ad-product-contents:not(.target-video) {
        margin-top: -9vw;
    }
    .exposure-media {
        height: 25vmin;
    }
}

/* for fonts */
@media only screen and (min-width: 450px) and (max-width: 1600px) {
    .font-8 {
        font-size: 0.9vw;
    }

    .font-10 {
        font-size: 1vw;
    }

    .font-11 {
        font-size: 1.05vw;
    }

    .font-12 {
        font-size: 1.1vw;
    }

    .font-13 {
        font-size: 1.15vw;
    }

    .font-14 {
        font-size: 1.2vw;
    }

    .font-16 {
        font-size: 1.3vw;
    }

    .font-18 {
        font-size: 1.4vw;
    }

    .font-20 {
        font-size: 1.5vw;
    }

    .font-22 {
        font-size: 1.6vw;
    }

    .font-24 {
        font-size: 1.7vw;
    }

    .font-25 {
        font-size: 1.75vw;
    }

    .font-28 {
        font-size: 1.9vw;
    }

    .font-30 {
        font-size: 2.0vw;
    }

    .font-32 {
        font-size: 2.1vw;
    }

    .font-36 {
        font-size: 2.2vw;
    }

    .font-40 {
        font-size: 2.4vw;
    }

    .font-45 {
        font-size: 2.65vw;
    }
}

/* Mobile */
@media only screen and (max-width: 450px) {
    .pc-container {
        display: none !important;
    }
    .pad-container {
        display: none !important;
    }
    .mobile-container {
        display: inherit !important;
    }
}
